import { Icon, type IconProps } from "~/components/vendorUI";
import { css } from "ui/css";
import { type ComponentPropsWithRef, forwardRef } from "react";

export const unstyledButtonCSS = css({
  "@layer base": { all: "unset" },
  cursor: "pointer",
});

type CrossButtonProps = Omit<ComponentPropsWithRef<"button">, "color"> &
  Omit<IconProps, "name"> & {
    "data-gtm"?: string;
    "data-tracking"?: string;
  };
export const CrossButton = forwardRef<HTMLButtonElement, CrossButtonProps>(
  ({ size, color, ...props }, ref) => (
    <button className={unstyledButtonCSS} ref={ref} {...props}>
      <Icon
        data-testid="cross-icon"
        data-gtm={props["data-gtm"]}
        data-tracking={props["data-tracking"]}
        name="Cross"
        size={size}
        color={color}
      />
    </button>
  ),
);
